<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="上架中" :value="2"></el-option>
          <el-option label="下架" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addCoupon">创建优惠券</el-button>
           <el-button type="warning" @click="adddialogVisible = true">发放优惠券</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 1">下架</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 2"
            >上架中</el-tag
          >
        </div>
      </template>
      <template #cash="{ row }">
        <div>
          <div v-if="row.way == 1">{{row.cash}}元</div>
          <div v-if="row.way == 2">{{(row.discount * 10).toFixed(1)}}折</div>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" @click="updateInfo(row)" size="small"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="deleteCoupon(row, 1)"
            v-if="row.status == 2"
            >下架</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="deleteCoupon(row, 2)"
            v-if="row.status == 1"
            >上架</el-button
          >
        </div>
      </template>
    </auto-table>

    <!-- 修改 生成 -->
    <el-dialog :visible.sync="dialogVisible1" width="800px">
      <el-form>
        <el-form-item label="优惠券名称" required>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" required>
          <el-select
            v-model="form.type"
            remote
            @focus.once="getCouType"
            reserve-keyword
          >
            <el-option
              v-for="(item, index) in cou_type_list"
              :key="index"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="优惠方式" required>
           <el-radio-group v-model="form.way">
            <el-radio :label="1">金额</el-radio>
            <el-radio :label="2">折扣</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item required label="优惠券面值" v-if="form.way == 1">
          <el-input v-model="form.cash"></el-input>
        </el-form-item>
        <el-form-item required label="折扣券折扣" v-if="form.way == 2">
          <el-input v-model="form.discount"  placeholder="折扣券折扣"></el-input>
          <p>九折券填0.9，填0免费兑换</p>
        </el-form-item>
         <el-form-item label="满减">
          <el-input v-model="form.full_amount"  placeholder="满多少可用"></el-input>
        </el-form-item>
        <el-form-item required label="有效时长（天）">
          <el-input v-model="duration"></el-input>
        </el-form-item>
        <el-form-item label="售价">
          <el-input v-model="form.price" placeholder="购买价格"></el-input>
        </el-form-item>

        <el-form-item label="优惠券描述">
          <el-input v-model="form.descr" placeholder="如:适用于xxx"></el-input>
        </el-form-item>
        <el-form-item label="使用说明">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="使用说明"
            v-model="form.explicate"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="可用类型" prop="type">
            <el-radio-group v-model="form.categories" @change="changeCategory">
              <el-radio label="">全部类目</el-radio>
              <el-radio label="体育运动">体育运动</el-radio>
              <el-radio label="夜校">夜校</el-radio>
            </el-radio-group>
          </el-form-item>

         <el-form-item label="可用类目" v-if="form.categories">
             <div @click="visible = true">{{
                haveCate.length == 0 ? "不限类目" : haveCate.join(",")
              }}</div>
            <el-transfer
                filterable
                filter-placeholder="请选择可以使用的类目"
                v-model="haveCate"
                :data="categories_child_list"
                :props="{
                  key: 'name',
                  label: 'name',
                }"
                :titles="['所有类目', '可用类目']"
              >
              </el-transfer>
          </el-form-item>



      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>

        <el-button v-if="form.id" type="primary" @click="update()"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="add()">确 定</el-button>
      </span>
    </el-dialog>


     <el-dialog
        title="发券"
        :visible.sync="adddialogVisible"
      >
        <div>
          <el-form
            label-width="120px"
            label-position="left"
          >
            <el-form-item label="用户电话">
              <user-select :userid.sync="selected_user"></user-select>
            </el-form-item>

            <el-form-item label="优惠券id">
              <el-input v-model="couponid" placeholder="券id"></el-input>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="adddialogVisible = false">取 消</el-button>
          <el-button
            style="background-color: #ff7e00; color: #ffffff"
            type="primary"
            @click="givecoupon"
            >确 定</el-button
          >
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { ObjectChangedValue } from "@/js/utils";
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "ID", value: "id" },
        { name: "名称", value: "name" },
        { name: "类型", value: "type" },
        { name: "面值", value: "cash" ,type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "creat_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 2,
      form:{
        way:1
      },
      dialogVisible1: false,
      duration:'',
      cou_type_list:[],
      adddialogVisible:false,
      selected_user:'',
      couponid:'',
      couponnum:1,
      categories_child_list:[],
      haveCate:[],
      visible:false
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    changeCategory(){
        if(this.form.categories){
          this.getCategoriesList()
        }else{
          this.haveCate = []
          this.form.categories_child = ''
        }
    },
     getCategoriesList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: this.form.categories == '体育运动' ? '课程' : this.form.categories,
          },
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
     givecoupon() {
      if (this.couponid == "") {
        this.$message({ message: "请选择券" });
        return;
      }
      if (this.selected_user == "") {
        this.$message({ message: "请选择用户" });
        return;
      }

      let me = this;
      let data = {
        user_id: this.selected_user,
        id: this.couponid,
      };
      me.$axios({
        method: "POST",
        url: "/user/userCoupon/insertUserCouponByid",
        data: data,
      }).then((res) => {
        this.$message({ message: res.data.message });
        this.adddialogVisible = false;
        this.id = ''
      });
    },
    add() {
      if (!this.form.name || !this.form.type) {
        this.$message({ message: "请填写券信息" });
        return;
      }
      let _this = this;
      let url = "/user/commoditycoupon/insert";
      this.form.status = 2;
      this.form.categories_child = this.haveCate.join(',');
      this.form.coupon_length = 8;
      let data = {
        ...this.form,
      };
      data.duration = this.duration * 60 * 24 || 86000;
      this.$axios.post(url, data).then((res) => {
        this.form = {};
        this.$message({ message: res.data.message });
        this.getList(1);
      });
      this.dialogVisible1 = false;
    },
    update() {
      this.form.duration = this.duration * 60 * 24 || 86000;
        this.form.categories_child = this.haveCate.join(',');
       let data = ObjectChangedValue(this.temp, this.form);
       if(Object.keys(data).length == 0){
         this.dialogVisible1  = false;
        return
       }
      data.id = this.form.id;

      let url = "/user/commoditycoupon/update";
      
      
      this.$axios.post(url, data).then((res) => {
        this.form = {};
      
        this.$message({ message: res.data.message });
         this.getList(1);
      });
      this.dialogVisible1  = false;
    },
    getCouType(query) {
      let url = "/user/commoditycouponType/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 50,
            currentPage: 1,
          },
        })
        .then((res) => {
          this.cou_type_list = res.data.data.rows;
        });
    },
    addCoupon() {
      this.dialogVisible1 = true
      this.form = {way:1}
    },
    updateInfo(row) {
       this.temp = Object.assign({},row);
      this.form = row;
      if(this.form.categories_child){
         this.haveCate = this.form.categories_child.split(',');
      }
       this.getCategoriesList()
      this.duration = this.form.duration / 60 / 24;
      this.dialogVisible1 = true
    },
    deleteCoupon(row, status) {
      let url = "/user/commoditycoupon/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: status,
        })
        .then((res) => {
            this.getList(1);
        });
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/commoditycoupon/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>