<template>
  <div id="consumerCoupon">
    <el-row style="background-color: #ffffff">
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="消费券列表" name="0">
        <coup-list></coup-list>
      </el-tab-pane>
      <el-tab-pane label="发放记录" name="1">
        <coupon-log></coupon-log>
      </el-tab-pane>
      <el-tab-pane label="消费券类型" name="2">
        <coupon-type></coupon-type>
      </el-tab-pane>
    </el-tabs>

    <!-- 推广设置 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="366px"
      :before-close="handleClose"
      title="推广设置"
    >
      <div style="padding-right: 20px; color: #434343; font-size: 14px">
        <div style="text-align: right">
          <samp style="color: #f56c6c">*</samp
          ><samp style="margin-right: 10px">指定用户ID</samp
          ><el-input v-model="nav.firstNav"></el-input>
        </div>
        <div style="text-align: right; margin: 30px 0">
          <samp style="color: #f56c6c">*</samp
          ><samp style="margin-right: 10px">券类型</samp
          ><el-input v-model="nav.firstNav"></el-input>
        </div>
        <div style="text-align: right">
          <samp style="color: #f56c6c">*</samp
          ><samp style="margin-right: 10px">价格</samp
          ><el-input v-model="nav.firstNav"></el-input>
        </div>
        <div style="text-align: right; margin: 30px 0">
          <samp style="color: #f56c6c">*</samp
          ><samp style="margin-right: 10px">每日上限</samp
          ><el-input v-model="nav.firstNav"></el-input>
        </div>
        <div style="text-align: right">
          <samp style="color: #f56c6c">*</samp
          ><samp style="margin-right: 10px">佣金设置</samp
          ><el-input v-model="nav.firstNav"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coupList from "./components/couponList.vue";
import couponLog from "./components/couponLog.vue";
import couponType from "./components/couponType.vue";
export default {
  components: {
    coupList,
    couponType,
    couponLog,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      adddialogVisible: false,
      nav: { firstNav: "卡券管理中心", secondNav: "消费券管理" },
      activeName: 0,
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀",
        },
      ],
      coups: [
        {
          label: "已上架",
          value: "2",
        },
        {
          label: "已下架",
          value: "1",
        },
      ],
      couponStatus: "2",
    };
  },
  methods: {
    handleClick() {},
    currentPageChange() {},
    handleClose(done) {
      done();
    },
  },
};
</script>

<style lang="less">
#consumerCoupon {
  background-color: #ffffff;
  padding: 0 20px;
  .an-box {
    display: flex;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    .an-p {
      color: #ff7e00;
      border-radius: 5px;
      border: #ff7e00 1px solid;
      padding: 4px 8px;
      margin-right: 20px;
      height: 24px;
      line-height: 24px;
      @media screen and(max-width:768px) {
        margin-bottom: 20px;
      }
    }
  }
}
.el-tabs__item {
  font-size: 16px !important;
}
.cell {
  text-align: center;
}
</style>
